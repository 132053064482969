import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Navigator from '../components/navigator'

const IndexPage = () => (
    <Layout hideNavigation={true}>
        <SEO title="Library of FREE Online Tools! – EpicToolkit.com" />

		<Navigator />

        <section className="hero">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title">Welcome to EpicToolkit.com!</h1>
                    <h2 className="subtitle">Your library of online tools for numbers, date and time, text, maths, financials, accounting, dummy content, and a lot more.</h2>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container content">
                <p>
                    Enjoy plenty of free online tools that will help you convert
                    units, clean stuff, generate dummy data, count, calculate,
                    minify, uglify, beautify, and much more.
                </p>
                <p>
                    We are adding new online tools regularly so come and check
                    them out!
                </p>
            </div>
        </section>
    </Layout>
)

export default IndexPage
